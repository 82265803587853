/* eslint-disable @typescript-eslint/no-explicit-any */

import { useDominoDistricts } from '@domui-hooks/useDominoDistricts';
import { getDistrictClubRoleTValue } from '@domui-utils/getDistrictClubRoleTValue';

import { useTranslation } from '@external/react-i18next';

export const useClubOptions = (clubId: string) => {
  const { t } = useTranslation();
  const { data, loading: loading1, error } = useDominoDistricts(clubId);

  return {
    options:
      data?.childClubSponsorOf.map(({ id, clubName, type }: any) => ({
        id,
        label: getDistrictClubRoleTValue(t, clubName, type),
        value: clubName,
        type,
      })) || [],
    loading1,
    error: error?.message,
  };
};
