import { TFunction } from 'i18next';

import { getClubRoleTkey } from '@utils/getClubRoleTkey';

const mapClubType = (type: string): string => {
  switch (type) {
    case 'Rotary Club':
      return 'Rotary';
    case 'Rotaract Club':
      return 'Rotaract';
    case 'Satellite Club':
      return 'Satellite';
    case 'Rotaract Satellite Club':
      return 'Rotaract Satellite';
    default:
      return type;
  }
};

export const getDistrictClubRoleTValue = (
  t: TFunction,
  clubRole: string,
  type: string
) => {
  const formattedType = mapClubType(type);
  return t(`club-roles.${getClubRoleTkey(clubRole)}`, {
    defaultValue: `${clubRole} (${formattedType})`,
    context: 'clubRoles',
  });
};
