import { useEffect, useMemo } from 'react';

import { useFetchRelations } from './useFetchRelations';

import { useSessionStorage } from '@repositories/storage/hooks';

import { DynamicData } from '@domui-domain/type';

export const useDominoDistricts = (orgId: string | null) => {
  const { getStorageData, updateStorage } = useSessionStorage<DynamicData>();

  const {
    data: fetchData,
    loading,
    error,
    fetchRelations,
  } = useFetchRelations();
  const userCacheId = `domino-user-option-districts-${orgId}`;

  useEffect(() => {
    const storageData = getStorageData(userCacheId);

    if (!storageData && orgId) {
      fetchRelations(orgId);
    }
  }, [orgId]);

  const data = useMemo(() => {
    const storageData = getStorageData(userCacheId);
    if (!loading && fetchData && orgId) {
      updateStorage(fetchData, userCacheId);
      return fetchData;
    }

    return storageData?.data;
  }, [fetchData, orgId, loading]);
  return { data, loading, error };
};
