import { TFunction } from 'i18next';

import { Option } from '@components/Formik/MultiSelectFilter/types';

import {
  CLUB_TYPE_ROTARACT,
  CLUB_TYPE_ROTARACT_SATELLITE,
  CLUB_TYPE_ROTARY,
  CLUB_TYPE_SATELLITE,
  MEMBERSHIP_TYPE_HONORARY,
  MEMBERSHIP_TYPE_HONORARY_ROTARACTOR,
  MEMBERSHIP_TYPE_MEMBER,
  MEMBERSHIP_TYPE_ROTARACTOR,
} from '@domui-domain/districts';

export const getClubTypesOptions = (t: TFunction): Option[] => [
  {
    id: '1',
    value: CLUB_TYPE_ROTARY,
    label: t('filters.club-type.rotary', 'Rotary'),
  },
  {
    id: '2',
    value: CLUB_TYPE_SATELLITE,
    label: t('filters.club-type.satellite', 'Rotary Satellite'),
  },
  {
    id: '3',
    value: CLUB_TYPE_ROTARACT,
    label: t('filters.club-type.rotaract', 'Rotaract'),
  },
  {
    id: '4',
    value: CLUB_TYPE_ROTARACT_SATELLITE,
    label: t('filters.club-type.rotaract-satellite', 'Rotaract Satellite'),
  },
];

export const getMemberTypesOptions = (t: TFunction) => {
  const options = [
    {
      id: '1',
      value: MEMBERSHIP_TYPE_MEMBER,
      label: t(
        'district-members.filters-member-type.member-option-label',
        'Rotarian'
      ),
    },
    {
      id: '2',
      value: MEMBERSHIP_TYPE_ROTARACTOR,
      label: t(
        'district-members.filters-member-type.rotaractor-option-label',
        'Rotaractor'
      ),
    },
    {
      id: '3',
      value: MEMBERSHIP_TYPE_HONORARY,
      label: t(
        'district-members.filters-member-type.honorary-option-label',
        'Honorary Rotarian'
      ),
    },
    {
      id: '4',
      value: MEMBERSHIP_TYPE_HONORARY_ROTARACTOR,
      label: t(
        'district-members.filters-member-type.honorary-rotaractor-option-label',
        'Honorary Rotaractor'
      ),
    },
  ];

  return options;
};
