/* eslint-disable @typescript-eslint/no-explicit-any */

import { useDominoClubOfficerRoles } from '@domui-hooks/useDominoClubOfficerRoles';

export const useDistrictOptions = (clubId: string) => {
  // const {
  //   data,
  //   loading,
  //   error,
  //   organizationTypeRoleMapping,
  // } = useOrganizationTypeRoleMapping();

  // useEffect(() => {
  //   if (clubId) {
  //     organizationTypeRoleMapping(clubId);
  //   }
  // }, [clubId]);
  const { data, loading, error } = useDominoClubOfficerRoles(clubId);

  // const rotaractOfficerRoles = Object.values(RolesForSelectRotaractOfficers);

  return {
    options:
      data?.clubOfficerRoles.map(
        ({ rolePk, roleName, organizationTypeName }: any) => ({
          id: rolePk,
          label: roleName,
          value: roleName,
          type: organizationTypeName,
        })
      ) || [],
    loading,
    error: error?.message,
  };
};
