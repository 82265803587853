import React from 'react';

import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { curryRight } from 'lodash';

import MultiSelectFilter from '@components/Formik/MultiSelectFilter';

import { isSomeFiltersApplied } from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';

import { Filter, MEMBER_FILTERS } from './types';

// interface Props extends MemberFilterProps {
//   filtersListToShow: string[];
// }
interface Option {
  id: string;
  value: string;
  label: string;
}
interface Props {
  multiSelectOptions: {
    clubs: { id: string; type: string; label: string; value: string }[];
    memberTypes: { id: string; value: string; label: string }[];
    districtRoles: { id: string; type: string; label: string; value: string }[];
  };
  initialFilterValues: Filter;
  applyFilter: (selectedOptions: Option[], name: MEMBER_FILTERS) => void;
  resetFilter: () => void;
  filtersListToShow: string[];
}
const Filters: React.FC<Props> = ({
  initialFilterValues,
  multiSelectOptions,
  applyFilter,
  resetFilter,
  filtersListToShow,
}) => {
  const { t } = useTranslation();

  const isFilterApplied = isSomeFiltersApplied(initialFilterValues);

  const handleApplyFilter = (filterName: MEMBER_FILTERS) =>
    curryRight(applyFilter)(filterName);

  const filterByClubName = filtersListToShow.includes(
    MEMBER_FILTERS.CLUB_NAMES
  );
  // const filterByClubId = filtersListToShow.includes(MEMBER_FILTERS.CLUB_IDS);

  const filterName = filterByClubName
    ? MEMBER_FILTERS.CLUB_NAMES
    : MEMBER_FILTERS.CLUB_NAMES;

  const filterSelectOptions = filterByClubName
    ? multiSelectOptions.clubs
    : multiSelectOptions.clubs;

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialFilterValues }}
      onSubmit={() => {}}
    >
      <Form className="district-members__filters desktop:flex">
        {filterByClubName && (
          <div className="px-5 desktop:px-3">
            <MultiSelectFilter
              title={t('district-members.filters-club-name-label', 'Clubs')}
              name={filterName}
              inputPlaceholder={t(
                'district-members.filters-club-name-search-placeholder',
                'Search by Club Name'
              )}
              resetFilter={resetFilter}
              applyFilter={handleApplyFilter(filterName)}
              options={filterSelectOptions}
            />
          </div>
        )}
        {filtersListToShow.includes(MEMBER_FILTERS.DISTRICT_ROLES) && (
          <div className="px-5 desktop:px-3">
            <MultiSelectFilter
              title={t(
                'district-members.filters-district-role-label',
                'District Role'
              )}
              name={MEMBER_FILTERS.DISTRICT_ROLES}
              inputPlaceholder={t(
                'district-members.search-district-role-placeholder',
                'Search by District Role'
              )}
              resetFilter={resetFilter}
              applyFilter={handleApplyFilter(MEMBER_FILTERS.DISTRICT_ROLES)}
              options={multiSelectOptions.districtRoles}
            />
          </div>
        )}
        {filtersListToShow.includes(MEMBER_FILTERS.MEMBER_TYPES) && (
          <div className="px-5 desktop:px-3">
            <MultiSelectFilter
              title={t(
                'district-members.filters-member-type-label',
                'Member Type'
              )}
              name={MEMBER_FILTERS.MEMBER_TYPES}
              inputPlaceholder={t(
                'district-members.filters-member-type-search-placeholder',
                'Filter by Member Type'
              )}
              resetFilter={resetFilter}
              applyFilter={handleApplyFilter(MEMBER_FILTERS.MEMBER_TYPES)}
              options={multiSelectOptions.memberTypes}
              isSearchable={false}
            />
          </div>
        )}
        {filtersListToShow.length > 1 && (
          <button
            type="button"
            className={classNames(
              isFilterApplied ? 'text-dark-blue-400' : 'text-gray-400',
              'text-md leading-6 pl-5 desktop:pl-2'
            )}
            onClick={() => {
              resetFilter();
            }}
            disabled={!isFilterApplied}
          >
            {t('clear-all', 'Clear All')}
          </button>
        )}
      </Form>
    </Formik>
  );
};

export default Filters;
